<template>
	<div class="popupCompatibilite">
		<div v-if="processing">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else>
			<template v-if="criteres_local.length > 2 && Object.keys(recap).length > 0 && stallions_local.length > 0">
				<!-- <b-button v-b-toggle.collpaseNote class="btnNote">
					<h3 ref="title-stallion" class="mb-0">3. {{ $t('pax.résultats') }}</h3>
					<span><font-awesome-icon :icon="['far', 'angle-down']" /></span>
				</b-button>
				<b-collapse visible id="collpaseNote" v-model="show_note">
					<div class="paragraphes">{{ $t('pax.comparer_jusqua_trois_jument_avec_letalon') }}</div>
					<div class="stallionGrade" v-for="stallion in stallions_local" :key="stallion.horse_id" :class="colorGrade(stallion.horse_id)">
						<div class="row no-gutters align-items-center" @click="displayDetailsCroisement(stallion)">
							<div class="col-5">
								<div class="stallionName">{{ stallion.horse_nom }}</div>
							</div>
							<div class="col-7 text-center">
								<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(stallion.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
							</div>
						</div>
					</div>
					<div class="text-right">
						<button class="btn btn-primary btn-next" @click="showCompatibilite">
							{{ $t('pax.comparer_jusqua_trois_juments') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
						</button>
					</div>
				</b-collapse> -->
				<div>
					<h3 ref="title-stallion" class="mb-0">3. {{ $t('pax.résultats') }}</h3>
					<div class="paragraphes">{{ $t('pax.comparer_jusqua_trois_jument_avec_letalon') }}</div>
					<div class="stallionGrade" v-for="stallion in stallions_local" :key="stallion.horse_id" :class="colorGrade(stallion.horse_id)">
						<div class="row no-gutters align-items-center" @click="displayDetailsCroisement(stallion)">
							<div class="col-5">
								<div class="stallionName">{{ stallion.horse_nom }}</div>
							</div>
							<div class="col-7 text-center">
								<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(stallion.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
							</div>
						</div>
					</div>
					<div class="text-right">
						<button class="btn btn-primary btn-next" @click="showCompatibilite">
							{{ $t('pax.comparer_les_juments') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" />
						</button>
					</div>
				</div>
			</template>
			<template v-else>
				<div class="closePopupCompatibilite">
					{{ $t('pax.selectionner_jusqua_mare_compatibilite') }}
				</div>
				<div class="blurry">
					{{ $t('pax.comparer_jusqua_trois_juments') }}
					<div class="stallionGrade mt-3"><div class="row no-gutters align-items-center"><div class="col-5"><div class="stallionName">AIR JORDAN Z*GFE</div></div><div class="col-7 text-center"><span class="bullet orange"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet green"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet red"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span></div></div></div>
					<div class="stallionGrade"><div class="row no-gutters align-items-center"><div class="col-5"><div class="stallionName">AIR JORDAN Z*GFE</div></div><div class="col-7 text-center"><span class="bullet orange"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet green"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet red"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span><span class="bullet"><font-awesome-icon :icon="['fas', 'circle']"/></span></div></div></div>
					<div class="text-right"><button class="btn btn-primary btn-next">{{ $t('pax.comparer_jusqua_trois_juments') }} <font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button></div>
				</div>
				<div v-if="stallions_local.length == 0" class="text-left"><a href="#" class="btn btn-dark btn-back" @click.prevent="goToStallionSelection">{{ $t('pax.selectionner_mare') }} <span class="icone"><font-awesome-icon :icon="['far', 'undo-alt']" /></span></a></div>
			</template>
		</div>

		<b-modal dialog-class="modal-pax" ref="modal" hide-footer id="paxModalSelection">
            <template v-slot:modal-title>
                {{ $t("pax.comparer_jusqua_trois_jument_avec_letalon") }}
            </template>

        	<div v-for="(stallion, index) in stallions_selected" class="form-group" :key="'stallion_'+index">
				<label for="season">{{ $t('pax.juments') }} {{ index+1 }}</label>
				<e-select
					v-model="stallions_selected[index]"
					track-by="horse_id"
					label="horse_nom"
					:placeholder="$t('pax.selectionner_une_jument')"
					:options="stallions_selection_modal"
					:allow-empty="false"
					:show-labels="false"
					:sortable="false"
					:searchable="false"
				>
					<template slot="singleLabel" slot-scope="{ option }">
						<div class="stallionGrade" :key="option.horse_id" :class="colorGrade(option.horse_id)">
							<div class="row no-gutters align-items-center" >
								<div class="col-5">
									<div class="stallionName">{{ option.horse_nom }}</div>
								</div>
								<div class="col-7 text-center">
									<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(option.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
								</div>
							</div>
						</div>
		            </template>
		            <template slot="option" slot-scope="{ option }">
						<div class="stallionGrade" :key="option.horse_id" :class="colorGrade(option.horse_id)">
							<div class="row no-gutters align-items-center" >
								<div class="col-5">
									<div class="stallionName">{{ option.horse_nom }}</div>
								</div>
								<div class="col-7 text-center">
									<span class="bullet" v-for="critere in criteres_local" :class="getColorBullets(option.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
								</div>
							</div>
						</div>
		            </template>
					<template slot="noOptions">{{ $t('global.list_empty') }}</template>
				</e-select>
			</div>

			<div class="text-center">
				<button class="btn btn-pax" @click="sendStallionsSelected">{{ $t('pax.comparer') }}</button>
			</div>
        </b-modal>

        <b-modal dialog-class="modal-pax" ref="modal_details" hide-footer>
			<template v-slot:modal-title class="d-flex">
				{{ $t('pax.detail_croisement') }}
				<button class="input-group-text px-3 modal-header-btn" @click="openIntroduction"><font-awesome-icon :icon="['fas', 'info']" /></button>
				<span class="elipsis">{{ stallion_choose.horse_nom }} X {{ mare.horse_nom }}</span>
			</template>

			<template v-if="Object.keys(stallion_choose).length > 0 && Object.keys(recap).length > 0">
				<div class="tabLine" v-for="critere in criteres_local" :key="critere.criteres_id">
					<div class="row no-gutters align-items-center" >
						<div class="col-6">
							{{ critere.critereslang_label }}
						</div>
						<div class="col text-center">
							<span class="bullet red" v-if="parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) < critere.criteres_red">
							</span>
							<span class="bullet green" v-else-if="parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) >= critere.criteres_green">
							</span>
							<span class="bullet orange" v-else-if="parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) >= critere.criteres_red && parseInt(recap[stallion_choose.horse_id][critere.criteres_id]) < critere.criteres_green">
							</span>
							<span class="bullet grey" v-else></span>
						</div>
					</div>
				</div>

				<div class="row no-gutters tools mt-4">
					<div class="col-12 px-1">
						<a class="modal-btn" :href="'https://www.gfeweb.com/' + lang + '/?redirect_to_fiche=1&horse_nom=' + mare.horse_nom + '&horse_sire=' + mare.horse_sire " target="_blank"><span><i>{{ mare.horse_nom }}</i> <b>{{ $t('pax.voir_fiche_etalon') }}</b></span></a>
					</div>
					<div class="col-12 px-1 mt-2">
						<router-link :to="{ name: 'ContractNew', params: {stallion_id: mare.horse_id, mare_id: stallion_choose.horse_id}}" target="_blank" class="modal-btn"><span><font-awesome-icon :icon="['far', 'money-check-edit']" /><b>{{ $t('pax.reserver_en_ligne') }}</b></span></router-link>
					</div>
				</div>
			</template>
		</b-modal>

		<b-modal dialog-class="modal-pax" ref="modal_intro" hide-footer>
			<template v-slot:modal-title>
				{{ $t('pax.pax_express') }}
			</template>

			<div v-html="text_intro"></div>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js";
	import _cloneDeep from 'lodash/cloneDeep';


	export default {
		name: "ResultCompatibiliteHReverse",
		props: {
			criteres: {
				type: Array,
				default: () => ( [] )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			displayCompatibilite: {
				type: Boolean,
				default: () => (false)
			},
			mare: {
				type: Object,
				default: () => ( null )
			}
		},
		mixins: [Navigation, PaxMixin, Tools],
		data () {
			return {
				processing: false,
				stallions_local: [],
				criteres_local: [],
				recap: {},
				stallions_selected: [null, null, null],
				show_note: true,
				stallions_selection_modal: [],
				stallion_choose: {},
				lang: 'fr',
				text_intro: ''
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.processing = true
				this.stallions_local = _cloneDeep(this.stallions)
				this.processing = false
				// setTimeout(() => this.show_note = false, 2000);
				this.stallions_selection_modal = this.stallions_local

				this.lang = this.getConfig('lang')
				this.text_intro = this.getConfig('translations')['pax_reverse'][this.lang]
			},

			async calculCompatibilite() {
				this.processing = true
				this.recap = await this.paxCalculCompatiblite(this.stallions_local, this.criteres_local)
				if(this.mare && this.mare.horse_id && this.stallions_local.length > 0) {
					const stallions_ids = this.stallions_local.map(stallion => stallion.horse_id)
				}
				this.processing = false
			},

			async showCompatibilite() {
				if(this.stallions_local.length > 3) {
					this.$refs.modal.show()
					return false
				}
				this.$emit('update:step_label', "comparaison_V")
				this.$emit('update:stallions_comparaison', this.stallions_local)
				this.$emit('update:displayCompatibilite', true)
			},

			getColorBullets(stallion_id, criteres_id = null) {
				if(this.recap[stallion_id] === undefined) {
					return false
				}


				let criteres_local = _cloneDeep(this.criteres_local)

				if(criteres_id != null) {
					criteres_local = criteres_local.filter(elem => {
						return elem.criteres_id == criteres_id
					})
				}

				let tab_bullets = []
				
				for (let index = 0; index < criteres_local.length; index++) {
					const element = criteres_local[index];

					if(parseInt(this.recap[stallion_id][element.criteres_id]) < element.criteres_red) {
						tab_bullets.push('red')
					}
					else if(parseInt(this.recap[stallion_id][element.criteres_id]) >= element.criteres_green) {
						tab_bullets.push('green')
					}
					else if(parseInt(this.recap[stallion_id][element.criteres_id]) >= element.criteres_red && parseInt(this.recap[stallion_id][element.criteres_id]) < element.criteres_green) {
						tab_bullets.push('orange')
					}
				}

				return tab_bullets
			},

			colorGrade(stallion_id) {
				// Pour les ronds : on fait un premier essai avec vert si compatibilité supérieure à 50%, orange si 30 à 50% et rouge si inférieure à 30%
				// Pour les fonds de cartouche étalons
				// Vert foncé si tous les ronds sont verts
				// Vert clair si que des ronds verts et 1 où 2 oranges et 0 rouges
				// Jaune si 3 oranges ou plus et 0 rouges ou 1 rouge et 1 ou 2 oranges
				// Orange si 1 rouge et 3 oranges ou plus ou 2 rouges et 1 ou 2 oranges
				// Rouge si 2 rouges et 3 oranges ou plus ou plus de 2 rouges

				const tab_bullets = this.getColorBullets(stallion_id)
				if(!tab_bullets) {
					return 'noir'
				}
				const nb_elems = tab_bullets.length
				const nb_elems_green = tab_bullets.filter((elem) => elem == 'green').length
				const nb_elems_red = tab_bullets.filter((elem) => elem == 'red').length
				const nb_elems_orange = tab_bullets.filter((elem) => elem == 'orange').length


				if(nb_elems == nb_elems_green) {
					return 'dark_green'
				}
				else if(nb_elems_green >= 1 && nb_elems_orange <= 2 && nb_elems_red == 0) {
					return 'green'
				}
				else if((nb_elems_red == 2 && nb_elems_orange >= 3) || nb_elems_red > 2) {
					return 'red'
				}
				else if((nb_elems_red == 1 && nb_elems_orange >= 3) || (nb_elems_red == 2 && nb_elems_orange <= 2)) {
					return 'orange'
				}
				else if((nb_elems_orange >= 3 && nb_elems_red == 0) || (nb_elems_red == 1 && nb_elems_orange <= 2)) {
					return 'yellow'
				}

				return 'noir'
			},

			goToStallionSelection() {
				this.$emit('update:step_label', "stallions")
			},

			sendStallionsSelected() {
				this.$refs.modal.hide()
				this.$emit('update:step_label', "comparaison_V")
				this.$emit('update:stallions_comparaison', this.stallions_selected)
			},

			async displayDetailsCroisement(stallion) {
				this.stallion_choose = stallion
				this.$refs.modal_details.show()
			},

			openIntroduction() {
				this.$refs.modal_details.hide()
				this.$refs.modal_intro.show()
			}
		},
		watch: {
			stallions(val) {
				this.stallions_local = _cloneDeep(val)
				this.stallions_selection_modal = _cloneDeep(val)
				this.calculCompatibilite()
			},
			'criteres': {
				deep: true,
				handler(val) {
					this.criteres_local = _cloneDeep(val)
					this.calculCompatibilite()
				}
			},
			'stallions_selected': {
				deep: true,
				handler(val) {
					let ids = []
					val.forEach(stallion => {
						if(stallion && stallion.horse_id) {
							ids.push(stallion.horse_id)
						}
					})
					this.stallions_selection_modal = this.stallions_local.filter(stallion => !ids.includes(stallion.horse_id))
				}
			},
			'mare': {
				deep: true,
				handler(val) {
					this.calculCompatibilite()
				}
			},
			show_note(val) {
				if(val) {
					window.scrollTo({ top: 250, behavior: 'smooth' });
					// setTimeout(() => this.$refs['title-stallion'].scrollIntoView({ behavior: 'smooth' }), 1000);
				}
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}
</script>
